import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Title = makeShortcode("Title");
const ImageCard = makeShortcode("ImageCard");
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl="https://www.figma.com/file/dFBwhGRAZlr6T2H2PCyx0H/Onboarding?type=design&t=m88XrWHHl3SWjTs2-1" codeUrl="#" mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <p>{`The onboarding screens are the first things that the users see when they launch your application. It’s sometimes known as first user experience (FUX). It is usually used to demonstrate some facts about the application such as how to use it, the application features, and how the application can help the users. Even though a lot of applications have ignored onboarding, a lot of user studies have shown that the importance of user onboarding relies upon two things which are users activation and users retention. User activation means that the user understands the application’s main features and how to use them. User retention means that the user has enough motivation to go back and reuse the application again. Onboarding not only benefits the users but also benefits the business to make revenue since they have only one shot to impress the users.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Use case pattern:`}</strong>{` In this pattern, various types of alternative onboarding methods are presented based on the content or feature. Please be aware, in every page there is Pros & Cons that should be read carefully.`}</p>
    </InlineNotification>
    <AnchorLinks small mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Case : Content is Mandatory</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Content is Optional</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Case : Without Content</AnchorLink>
    </AnchorLinks>
    <div className="divi" />
    <h2>{`Case : Content is Mandatory`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`This flow is used to inform users about the new content/features that have been created and need to be conveyed to users to be known, so the nature of the onboarding process in this flow is mandatory.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a new user install the app, I directly want to see values of the app as a product and services, so I could identify if my needs and my goals would be fulfilled.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the app launch.`}</li>
      <li parentName="ul">{`Language used is in English.`}</li>
      <li parentName="ul">{`Minimum 2 screen of onboarding required, 3 ideal, more than 3 should be avoided.`}</li>
    </ul>
    <br />
    <ImageCard title="Onboarding Flow" titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/dFBwhGRAZlr6T2H2PCyx0H/Onboarding?node-id=2153%3A71209" actionIcon="arrowRight" subTitleColor="dark" subTitle="Content is Mandatory" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/onboarding/onboarding-mobile-1.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="16:9" colLg="6" text="Pros : Could elaborate more about the values of the app and give user time to focus into the onboarding message." captionTitle="Do" caption="Use it if we want to educate user as they install our app for the first time and if necessary submit a disclaimer that drastically affects the journey and user satisfaction if it is not conveyed early on in the installation." mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="16:9" colLg="6" text="Cons : User could skipped it away because we give them the control to do so and we prolonged time to login for user as they must landing on the onboarding page." captionTitle="Don't" caption="Using it if user is ready with the product/service knowledge of the app and not really important to educate them when onboard and if the application is easy enough to understand & use so that it does not require education on the delivery of values and how to use it." mdxType="DoDont"></DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Content is Optional`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`This flow is used to inform users about the new content/features as added value that have been created and conveyed to users to know, so the nature of the onboarding process in this flow is optional.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a new user install the app, I want to be informed about values of the app as a product and services as well as do login/register, so I could know simply and briefly if my needs and my goals would be fulfilled.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the app launch.`}</li>
      <li parentName="ul">{`Language used is in English.`}</li>
      <li parentName="ul">{`Minimum 2 screen of onboarding required, 3 ideal, more than 3 should be avoided.`}</li>
      <li parentName="ul">{`Login/Register button presented on each onboarding page.`}</li>
    </ul>
    <br />
    <ImageCard title="Onboarding Flow" titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/dFBwhGRAZlr6T2H2PCyx0H/Onboarding?node-id=2155%3A79236" actionIcon="arrowRight" subTitleColor="dark" subTitle="Content is Optional" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/onboarding/onboarding-mobile-2.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="16:9" colLg="6" text="Pros : Could elaborate briefly about the values of the app and giving user preferences between direct login/register and read the onboarding message." captionTitle="Do" caption="Use it if we still want to educate user as they install our app for the first time but reduce the prolonged time to login/register." mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="16:9" colLg="6" text="Cons : User could skipped it away because we give them the control to do so" captionTitle="Don't" caption="Avoid using it if user is ready with the product/service knowledge of the app and not really important to educate them when onboard and if the application is easy enough to understand & use so that it does not require education on the delivery of values and how to use it." mdxType="DoDont"></DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Case : Without Content`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`Exciting news! The code for this amazing pattern is on its way. We’re working hard to ensure it’s available to you just like our other patterns. Stay tuned for the upcoming release and get ready to incorporate this incredible feature into your projects!`}</p>
    </InlineNotification>
    <p>{`This flow is used when there is no new content/feature that needs to be directly informed to the user to know. Users can be directed to directly enter/register.`}</p>
    <p><strong parentName="p">{`User Story :`}</strong>{` As a new user install the app, I want to directly login/register, so I could immediately exploring the app with all of the features inside.`}</p>
    <Title mdxType="Title"> Requirement Scenario / Acceptance Criteria </Title>
    <ul>
      <li parentName="ul">{`Flow starts after the app launch.`}</li>
      <li parentName="ul">{`Language used is in English.`}</li>
      <li parentName="ul">{`Minimum 2 screen of onboarding required, 3 ideal, more than 3 should be avoided.`}</li>
      <li parentName="ul">{`Interception page is provided for very quick onboarding experience only, could be take out if needed.`}</li>
    </ul>
    <br />
    <ImageCard title="Onboarding Flow" titleColor="dark" iconColor="dark" hoverColor="dark" aspectRatio="2:1" href="https://www.figma.com/file/dFBwhGRAZlr6T2H2PCyx0H/Onboarding?node-id=2155%3A88845" actionIcon="arrowRight" subTitleColor="dark" subTitle="Without Content" mdxType="ImageCard">
      <img {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/pattern/onboarding/onboarding-mobile-3.png",
        "alt": "Pattern"
      }}></img>
    </ImageCard>
    <Title mdxType="Title"> Pros, Cons & Do, Don’t </Title>
    <DoDontRow mdxType="DoDontRow">
  <DoDont aspectRatio="16:9" colLg="6" text="Pros : User could be directly login/register." captionTitle="Do" caption="Use it if we don't need to educate user as they install our app." mdxType="DoDont"></DoDont>
  <DoDont type="dont" aspectRatio="16:9" colLg="6" text="Cons : There’s no introduction at the beginning, so it should be considered to implement tour feature inside if possible." captionTitle="Don't" caption="If we need to educate user as they install our app." mdxType="DoDont"></DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      